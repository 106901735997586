<script lang='ts'>

  import { navigateWithReset } from '@/helpers/navigateWithReset'

  import Icon from '@/components/icons/Icon.svelte'
  import FakeButton from '@/components/ui/FakeButton.svelte'

  import { ChatTab } from '@/definitions/chat/common'
  import { _ } from '@/libs/i18n'

  export let active = '',
    iso = '',
    search = '',
    selectedCategory = '',
    correctionMode: 'test' | 'read' = 'test',
    chatPage = '',
    readVocabularyFrom = 'vocabulary',
    showFilters = false

  const selectTab = (tab: ChatTab) => {
    active = tab
    navigateWithReset(`/${iso}/chat/${tab}`)
  }

</script>

<div class='chat-filters _gap24' class:-chatUser={chatPage === 'chatUser'} class:-show={showFilters}>
  {#if chatPage !== 'chatUser'}
    <div class='_tabs-wrapper -chat' role='tablist'>
      <button
        class:_transparent-gray={active !== ''}
        tabindex='0'
        type='button'
        on:click={() => selectTab('')}
        on:keypress={() => {}}
      >
        <Icon icon='ChatCircleText' weight='fill' />
        <span class='label _desktopOnly'>{$_('menu.messages')}</span>
      </button>
      <button
        class:_transparent-gray={active !== 'users'}
        tabindex='0'
        type='button'
        on:click={() => selectTab('users')}
        on:keypress={() => {}}
      >
        <Icon icon='User' weight='fill' />
        <span class='label _desktopOnly'>{$_('chat.users')}</span>
      </button>
      <button
        class:_transparent-gray={active !== 'corrections'}
        tabindex='0'
        type='button'
        on:click={() => selectTab('corrections')}
        on:keypress={() => {}}
      >
        <Icon icon='CheckCircle' weight='fill' />
        <span class='label _desktopOnly'>{$_('chat.corrections')}</span>
      </button>
      <button
        class:_transparent-gray={active !== 'bookmarks'}
        tabindex='0'
        type='button'
        on:click={() => selectTab('bookmarks')}
        on:keypress={() => {}}
      >
        <Icon icon='BookmarkSimple' weight='fill' />
        <span class='label _desktopOnly'>{$_('chat.bookmarks')}</span>
      </button>
    </div>
  {/if}
  <div class='search-and-select' class:-hide={!showFilters}>
    <FakeButton class='_mobileOnly' onClick={() => {showFilters=!showFilters}}>
      <Icon icon='X' weight='regular' />
    </FakeButton>
    <input class='search' placeholder={$_('chat.search')} type='text' bind:value={search} />
    {#if search}
      <FakeButton class='clearInput' onClick={() => {search = ''}}>
        <Icon icon='X' size={16} weight='regular' />
      </FakeButton>
    {/if}
    {#if selectedCategory === 'corrections'}
      <div class='vocabulary-switch'>
        <label class='label'>
          <select bind:value={correctionMode} name='testMode'>
            <option value='test'>{$_('chatCorrect.test')}</option>
            <option value='read'>{$_('chatCorrect.read')}</option>
          </select>
        </label>
      </div>
    {/if}
    {#if chatPage === 'chatUser'}
      <div class='vocabulary-switch'>
        <label class='label'>
          <select bind:value={readVocabularyFrom}>
            <option value='friendVocabulary'>{$_('chat.friendsVocabulary')}</option>
            <option value='vocabulary'>{$_('chat.myVocabulary')}</option>
          </select>
        </label>
      </div>
    {/if}
  </div>
</div>

<style lang='scss'>
  .chat-filters {
    padding-bottom: 0;
    border-bottom: none;
    border-radius: 2.4rem 2.4rem 0 0;

    &.-chatUser {
      border-top: none;
      border-radius: 0;
    }

    &.-show {
      gap: 1.2rem;
    }
  }

  .search-and-select {
    position: relative;
    display: flex;
    gap: 1.6rem;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-block: 1.2rem;

    &.-hide {
      display: none;
    }

    > .search {
      width: 100%;
    }

    > :global(.clearInput) {
      position: absolute;
      right: 1.2rem;
      bottom: 1.8rem;
    }
  }

  @media(max-width: 768px) {
    .chat-filters {
      padding-bottom: 0;
      border-bottom: none;

      &.-chatUser {
        border-top: none;
        border-radius: 0;
      }

      &.-show {
        display: flex;
        padding-bottom: 1.6rem;
        border-bottom: 0.1rem solid var(--Gray-Light);
      }
    }

    .search-and-select {
      > :global(.clearInput) {
        position: absolute;
        right: 2.8rem;
        bottom: 2.4rem;
      }
    }
  }
</style>
