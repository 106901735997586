<script lang='ts'>
  import { resizeTextarea } from '@/helpers/actions/resizeTextarea'
  import { _ } from '@/helpers/i18n'

  import Icon from '@/components/icons/Icon.svelte'
  import FakeButton from '@/components/ui/FakeButton.svelte'
  import Overlay from '@/components/ui/Overlay.svelte'

  import { ChatMessage } from '@/definitions/langoid'

  export let isMyMsg: boolean,
    hasExplanation = false,
    msg: ChatMessage,
    send = (obj: any, type: string) => {
    },
    hideContextMenu = () => { }

  let showCorrectionForm = false,
    showExplanationReqForm = false,
    showChooseWords = false,
    noteInput: string,
    correctionMessageInput: string,
    explainWords: any = [],
    radio: string

  const clickOnCorrection = () => {
    correctionMessageInput = correctionMessageInput || msg.correction?.correction || msg.message
    noteInput = noteInput || msg.correction?.correction_note
    showCorrectionForm = !showCorrectionForm
  }

  const requestExplanation = () => {
    explainWords = []
    msg.posTagged.forEach((word: any) => {
      if (word.content) {
        explainWords.push({
          checked: false,
          id: Object.values(word.original)[0],
          word: word.content
        })
      }
    })
    showExplanationReqForm = !showExplanationReqForm
  }

  const submitCorrectionForm = () => {
    send({
        correction: correctionMessageInput || msg.message,
        correctionNote: noteInput || '',
        messageId: msg.id,
        receiver: msg.receiver,
        sender: msg.sender
      },
      'messageCorrect')
    showCorrectionForm = false
    hideContextMenu()
  }

  const submitExplanationReq = (unclear = false) => {
    if (!unclear && explainWords.filter((w: any) => w.checked).length < 1) {
      return // todo
    }

    const dataToSend = {
      messageId: msg.id,
      receiver: msg.receiver,
      sender: msg.sender,
      unclear: '',
      words: []
    }

    if (unclear) {
      dataToSend.unclear = 'yes'
    } else {
      dataToSend.words = explainWords
    }

    send(dataToSend, 'explanationRequest')
    showExplanationReqForm = false
    showChooseWords = false
    hideContextMenu()
  }

  const handleOnChange = (e: Event) => {
    const targetEvent = e.target as HTMLInputElement
    const { value, checked } = targetEvent
    explainWords.find((i: any) => i.word === value).checked = checked
  }

  const sendFormUnclear = () => {
    if (radio === 'words') {
      showChooseWords = true
    } else if (radio === 'unclear') {
      submitExplanationReq(true)
    }
  }
</script>

{#if !isMyMsg}
  <div class='section-wrapper'>
    <FakeButton onClick={clickOnCorrection}>
      <Icon icon='PencilSimple' weight='regular' />
      {$_('chat.correct')}
    </FakeButton>
    {#if !hasExplanation}
      <hr class='_desktopOnly' />
      <FakeButton disabled={!!msg.explanation} onClick={requestExplanation}>
        <Icon icon='Question' weight='regular' />
        {$_('chat.askForExplanation')}
      </FakeButton>
    {/if}
  </div>
{/if}

{#if showCorrectionForm}
  <Overlay onClick={() => {showCorrectionForm = false}}>
    <form class='chat-edit-form' on:submit|preventDefault={submitCorrectionForm}>
      <h2 class='explanationTitle'>{$_('chat.suggestCorrection')}</h2>
      <label>{$_('chat.correctedMessage')}<textarea bind:value={correctionMessageInput} use:resizeTextarea /></label>
      <label>{$_('explanationBox.explanation')}<textarea cols='26' rows='3' bind:value={noteInput} use:resizeTextarea /></label>
      <input type='submit' value={$_('chat.sendCorrection')} />
    </form>
  </Overlay>
{/if}

{#if showExplanationReqForm}
  <Overlay onClick={() => { showExplanationReqForm = false; showChooseWords = false }}>
    <div class='explanation-container'>
      <h2 class='explanationTitle'>{$_('chat.askForExplanation')}</h2>
      {#if showChooseWords}
        <form class='explanation-req-form' on:submit|preventDefault={() => submitExplanationReq()}>
          {#each msg.posTagged as item}
            {#if typeof item === 'object' && 'content' in item && item.content}
              <label class='singleCheckboxContainer'>
                <input class='explanationCheckbox' type='checkbox' value={item.content} on:change={handleOnChange} name='explain-word' />
                <span>{item.content}</span>
              </label>
            {/if}
          {/each}
          <input type='submit' value={$_('chat.send')} />
        </form>
      {:else}
        <form class='explanation-req-form' action='' on:submit|preventDefault={sendFormUnclear}>
          <p class='explanationRadio'>
            <label><input type='radio' value='words' bind:group={radio} />{$_('chat.chooseUnclearWords')}</label></p>
          <p class='explanationRadio'>
            <label><input type='radio' value='unclear' bind:group={radio} />{$_('chat.knowButDontUndarstand')}</label>
          </p>
          <input class='explanationSubmitButton' type='submit' value='Continue' />
        </form>
      {/if}
    </div>
  </Overlay>
{/if}

<style lang='scss'>
  .explanation-container, .chat-edit-form {
    > .explanationTitle {
      margin-bottom: 3.2rem;
    }
  }

  .chat-edit-form {
    > input,
    :global(textarea) {
      display: block;
      width: 100%;
    }

    > label {
      margin-bottom: 1.6rem;
      font-size: 1.4rem;
    }
  }

  .explanation-req-form {
    margin-bottom: 0;

    > .singleCheckboxContainer {
      display: inline-flex;
      margin-right: 1.2rem;
      margin-bottom: 2.4rem;
      padding: 0.4rem 0.8rem;
      font: var(--Regular-400);
      border: 0.1rem solid var(--Gray-Medium);
      border-radius: 0.8rem;
      cursor: pointer;

      > .explanationCheckbox {
        margin-right: 0.4rem;
      }
    }

    > .explanationRadio {
      margin-bottom: 1.6rem;
    }

    > .explanationSubmitButton {
      width: 100%;
    }
  }

  .section-wrapper {
    position: absolute;
    top: 4rem;
    left: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 17.8rem;
    background: var(--form-background);
    border: 0.1rem solid var(--Gray-Light);
    border-radius: 8px;
    box-shadow: var(--Shadow-X);

    > :global(span) {
      gap: 1.2rem;
      align-items: center;
      padding: 0.8rem;
      color: var(--text-primary-color);

      &:hover {
        color: var(--text-tertiary-color);
      }
    }
  }

  @media(max-width: 768px) {
    .section-wrapper {
      position: relative;
      top: unset;
      left: unset;
      gap: 1.2rem;
      border: none;
      box-shadow: none;

      > :global(span) {
        padding: 0;
      }
    }
  }
</style>
