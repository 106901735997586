<script lang='ts'>
  import { onDestroy, onMount } from 'svelte'
  import { get } from 'svelte/store'
  import { link } from 'svelte-routing'

  import { EXECUTION_TIME_HACK } from '@/config/constants'

  import { resizeTextarea } from '@/helpers/actions/resizeTextarea'
  import { getIdFromIso, getIsoFromId, getLearningLanguageId } from '@/helpers/apiCall'
  import { autocomplete, emojify } from '@/helpers/emojiHelpers'
  import { _ } from '@/helpers/i18n'
  import { goToPseudoHash, keysCheck, toInt, truncate } from '@/helpers/mixHelpers'
  import { navigateWithReset } from '@/helpers/navigateWithReset'
  import { send } from '@/helpers/socket'

  import AudioRecorder from '@/components/audio/AudioRecorder.svelte'
  import ChatFilters from '@/components/chat/ChatFilters.svelte'
  import Keyboard from '@/components/chat/Keyboard.svelte'
  import Flag from '@/components/forum/Flag.svelte'
  import Icon from '@/components/icons/Icon.svelte'
  import FakeButton from '@/components/ui/FakeButton.svelte'
  import LanguageSelect from '@/components/ui/LanguageSelect.svelte'

  import ChatMessage from './ChatMessage.svelte'
  import Emojis from './Emojis.svelte'
  import SuggestedEmojis from './SuggestedEmojis.svelte'

  import { ChatMessage as ChatMessageType, SelectedWord, Vocabulary } from '@/definitions/langoid'
  import { MixedStoreData } from '@/definitions/stores'
  import { chatSelectedWord, mixStore, userStorredConfig } from '@/store'

  export let showOlder = () => { },
    selectedUser: number | undefined,
    selectedCategory: 'bookmarks' | 'corrections' | undefined,
    selectedWord: SelectedWord = { content: '', id: 0, wordId: 0 },
    lastPage: boolean,
    messages: ChatMessageType[] = [],
    vocabulary: Record<number, Vocabulary> = {},
    readVocabularyFrom = 'vocabulary',
    iso = '',
    users: any = {},
    histories: Record<number, any> = {},
    correctionMode: 'test' | 'read' = 'test',
    chatPage = '',
    showFilters = false

  const addHeight = 5
  const TIME_TO_SAVE_RECORDING = 2000
  let list: HTMLDivElement,
    messageField = '',
    input: HTMLTextAreaElement,
    myId: number,
    showKeyboard = false,
    showEmoji = false,
    emojiMatch: string[] = [],
    username = '',
    isInitialLoad = true,
    scrollPosition = 'bottom',
    lastReceivedSent: string = '',
    currentUser: any = {},
    recordingInProgress = false,
    reply = { excerpt: '', id: 0 },
    showOthersLang = false

  const onAfterSuccess = (data: any) => {
    send({
      audio: data.path,
      languageId: getLearningLanguageId(),
      msg: data.path,
      recipient: toInt(window.location.href.split('/').pop())
    })
    setTimeout(() => {
      recordingInProgress = false
    }, TIME_TO_SAVE_RECORDING)
  }

  const onStartRecording = () => {
    recordingInProgress = true
  }
  const markAllAsRead = () => {
    if (selectedUser) {
      const received = messages.filter((m) => m.sender === selectedUser)
      const lastReceived = received[received.length - 1]
      if (lastReceived && (lastReceivedSent !== JSON.stringify(lastReceived?.posTagged))) {
        send({
          friendId: selectedUser,
          messageId: lastReceived.id
        }, 'markAsRead')
        lastReceivedSent = JSON.stringify(lastReceived?.posTagged)
      }
    }
  }
  const onVisibilityChange = (e: Event) => {
    const targetEvent = e.target as Document
    if (targetEvent.visibilityState === 'visible') markAllAsRead()
    document.removeEventListener('visibilitychange', onVisibilityChange)
  }

  let languageId: number
  onMount(() => {
    languageId = getIdFromIso(iso)
    const setPos = () => {
      scrollPosition = getScrollPosition()
    }

    if (list) list.addEventListener('scroll', setPos)

    return () => {
      list.removeEventListener('scroll', setPos)
    }
  })

  if (selectedCategory) {
    readVocabularyFrom = 'vocabulary'
    isInitialLoad = true
  }

  let lastLoad = 0
  $: if (messages.length > 0) {
    const now = Date.now()
    if ((now - lastLoad) > 500) {
      lastLoad = now
      myId = get(userStorredConfig).id
      const isBottom = scrollPosition === 'bottom'
      setTimeout(() => {
        if (list && (isInitialLoad || isBottom)) {
          list.scrollTo(0, list.scrollHeight)
          isInitialLoad = false
        }
      }, EXECUTION_TIME_HACK)

      if (messages.filter((m) => m.sender === selectedUser && !m.msg_read).length > 0) {
        if (document.hidden) {
          document.addEventListener('visibilitychange', onVisibilityChange)
        } else {
          markAllAsRead()
        }
      }
    }
  }

  const setUserAndCategory = (user: number, category: 'bookmarks' | 'corrections' | undefined = undefined, msgId = 0) => {
    selectedUser = user
    selectedCategory = category
    if (msgId) {
      goToPseudoHash('msg')
    }
  }

  $: if (emojiMatch?.length > 0) {
    showEmoji = false
  }

  const sendMessage = () => {
    if (messageField.trim().length > 0) {
      send({
        languageId: getLearningLanguageId(),
        msg: messageField,
        recipient: toInt(window.location.href.split('/').pop()),
        reply
      })
    }
    messageField = ''
    resetReply()
  }
  const findUser = (id: number) => users[id] || {}

  const getUsersName = (userId: number) => {
    if (userId === myId) return 'me'

    const user = findUser(userId)
    currentUser = user
    return user.name || user.nick || `id: ${userId}`
  }
  if (selectedUser) {
    username = getUsersName(selectedUser || 0)
    isInitialLoad = true
  }

  const clickOnWord = (item: any) => {
    selectedWord = { content: '', id: 0, wordId: 0 }

    if (item) {
      const { original = {}, content = '' } = item
      const [word, id] = Object.entries(original)?.[0] || [0, 0]

      if (word && id) {
        selectedWord = { content, id, word }
        chatSelectedWord.set(selectedWord)
      }
    }
  }

  const handleSendMessage = () => {
    sendMessage()
  }

  const handleShowKeyboard = () => {
    showEmoji = false
    showKeyboard = !showKeyboard
  }

  const handleShowEmoji = () => {
    showKeyboard = false
    showEmoji = !showEmoji
  }

  const clickOnKey = (char: string): void => {
    messageField += char
    input.focus()
  }

  const clickOnEmoji = (emoji: string): void => {
    clickOnKey(emoji)
    showEmoji = false
  }

  const handleKeyUp = () => {
    messageField = emojify(messageField)
    emojiMatch = autocomplete(messageField)
  }

  const clickOnSuggestedEmoji = (emoji: string) => {
    const textArr = messageField.split(' ')
    textArr.pop()
    textArr.push(emoji + ' ')
    messageField = textArr.join(' ')
    emojiMatch = []
    input.focus()
  }

  const getScrollPosition = () => {
    const listHeight = list.getBoundingClientRect().height
    return list.scrollHeight - list.scrollTop < listHeight + addHeight ? 'bottom' : 'up'
  }

  const handleLoadMore = () => {
    const oldScrollHeight = list.scrollHeight
    showOlder()
    setTimeout(() => {
      const newScrollHeight = list.scrollHeight
      list.scrollTo(0, newScrollHeight - oldScrollHeight)
    }, EXECUTION_TIME_HACK)
  }

  const setReply = (id: number, excerpt: string) => {
    reply = { excerpt: truncate(excerpt), id }
  }

  const resetReply = () => {
    setReply(0, '')
  }

  let newMessages = {}
  const unsubscribe = mixStore.subscribe((data: MixedStoreData) => {
    newMessages = data.messagesCounts
  })

  let othersShown = false
  const toggleOthers = () => {
    othersShown = !othersShown
  }

  onDestroy(() => {unsubscribe()})
</script>
{#if selectedUser || selectedCategory}
  {#if selectedUser}
    <div class='chat-header _gap24'>
      <div class='user-info'>
        <a class='_icon -noBorder _mobileOnly' href={`/${iso}/chat/users`} use:link>
          <Icon icon='ArrowLeft' weight='regular' />
        </a>
        <div class='avatar-wrapper'>
          {#if currentUser.avatar}
            <img
              class='avatarImage'
              alt=''
              src={currentUser.avatar}
              width='60'
            />
          {/if}
          {username}
        </div>
        {#if selectedUser}
          {@const currentLangId = getIdFromIso(iso)}
          <div class='all-language-flags _horizontal'>
            <div class='active-language'>
              {#if !Object.keys(histories || {}).includes(currentLangId.toString())}
                <span class='languageSwitch'>
                  <Flag id={currentLangId} />
                  <hr class='_desktopOnly' />
                </span>
              {/if}
              {#each Object.keys(histories || {}) as lang}
                {@const item = histories[lang]}
                {#if item.iso === iso}
                  <a
                    class='languageSwitch'
                    href='/{item.iso}/chat/{selectedUser}'
                    on:click|preventDefault={() => navigateWithReset(`/${item.iso}/chat/${selectedUser}`)}
                    on:keypress={() => {}}
                  >
                    <Flag id={item.id_language} />
                    <hr class='_desktopOnly' />
                    <span class='_badge'>{newMessages?.[selectedUser]?.[item.id_language] || ''}</span>
                  </a>
                {/if}
              {/each}
              {#if Object.keys(histories).length}
                <FakeButton onClick={() => {showOthersLang = !showOthersLang}}>
                  <span class='_mobileOnly'><Icon icon='CaretDown' size={16} weight='regular' /></span>
                </FakeButton>
              {/if}
            </div>
            {#if Object.keys(histories).length}
              <div class='other-languages' class:-show={showOthersLang}>
                {#each Object.keys(histories || {}) as lang}
                  {@const item = histories[lang]}
                  {#if item.iso !== iso}
                    <a
                      class='languageSwitch'
                      href='/{item.iso}/chat/{selectedUser}'
                      on:click|preventDefault={() => navigateWithReset(`/${item.iso}/chat/${selectedUser}`)}
                      on:keypress={() => {}}
                    >
                      <Flag id={item.id_language} />
                      <span class='_badge'>
                      {newMessages?.[selectedUser]?.[item.id_language] || ''}
                    </span>
                    </a>
                  {/if}
                {/each}
              </div>
            {/if}
            <div class='new-chat-icon'>
              <FakeButton onClick={toggleOthers}>
                <Icon icon='Plus' size={20} weight='regular' />
              </FakeButton>
            </div>
            {#if othersShown}
              <div class='new-chat-languages'>

                <LanguageSelect onChange={(lang) => {navigateWithReset(`/${getIsoFromId(lang)}/chat/${selectedUser}`)}} selectedLanguage={getIdFromIso(iso)} />
              </div>
            {/if}
          </div>
        {/if}
      </div>
      <div class='_mobileOnly'>
        <FakeButton onClick={() => {showFilters = !showFilters}}>
          <span class='_icon -noBorder' class:-active={showFilters}><Icon icon='Sliders' weight='regular' /></span>
          <span class='filterUserHeading _mobileOnly' class:-hide={selectedUser}>{$_('forum.filters')}</span>
        </FakeButton>
      </div>
    </div>
    <ChatFilters {chatPage} {iso} bind:showFilters bind:correctionMode bind:readVocabularyFrom />
  {/if}
  <div bind:this={list} class='messages-holder _gap24' class:-chatUser={chatPage === 'chatUser'}>
    {#if !lastPage}
      <button class='showMore' type='button' on:click={handleLoadMore}>{$_('showMore')}</button>
    {/if}
    {#each messages as msg, k (msg.id)}
      <ChatMessage
        {clickOnWord}
        {correctionMode}
        {getUsersName}
        {iso}
        {languageId}
        {msg}
        {myId}
        prev={(messages?.[k - 1]?.created_at || '')?.split(' ')[0]}
        {readVocabularyFrom}
        {selectedCategory}
        {selectedUser}
        {send}
        {setReply}
        {setUserAndCategory}
        {vocabulary}
      />
    {:else}
      {#if selectedCategory}
        <div class='no-messages'>{selectedCategory === 'bookmarks'
          ? $_('chat.noBookmarks')
          : $_('chat.noCorrections')}</div>
      {:else}
        No messages
      {/if}
    {/each}
    <br />
  </div>
  {#if selectedUser}
    <div class='user-input-wrapper'>
      {#if showKeyboard}
        <Keyboard {iso} variant='chat' />
      {/if}
      {#if emojiMatch.length > 0}
        <SuggestedEmojis clickOnEmoji={clickOnSuggestedEmoji} {emojiMatch} />
      {:else if showEmoji}
        <Emojis {clickOnEmoji} />
      {/if}
      <form class='chat-input-form' class:-inProgress={recordingInProgress} on:submit|preventDefault={handleSendMessage}>
        {#if reply.id}
          <div class='reply-preview'>
            <button
              class='closeButton'
              type='button'
              on:click={resetReply}
              on:keypress={() => {}}
            >
              <Icon icon='X' size={16} weight='regular' />
            </button>
            {reply.excerpt}
          </div>
        {/if}
        <button
          class='inputMessageButton -keyboard'
          class:-active={showKeyboard}
          type='button'
          on:click|preventDefault={handleShowKeyboard}
        >
          <Icon icon='Keyboard' weight='fill' />
        </button>
        <button class='inputMessageButton -emoji' class:-active={showEmoji} type='button' on:click|preventDefault={handleShowEmoji}>
          <Icon icon='Smiley' weight='fill' />
        </button>
        <textarea
          bind:this={input}
          class='inputMessageField'
          cols='50'
          placeholder={$_('chat.messagePlaceholder')}
          rows='1'
          bind:value={messageField}
          on:keyup={handleKeyUp}
          on:keypress={(e) => {
          keysCheck(e, () => {
            sendMessage()
            messageField = ''
          })
        }}
          use:resizeTextarea
        />

        <button class='inputMessageButton -sendbutton' disabled={!messageField} type='submit'>
          <Icon icon='PaperPlaneTilt' weight='fill' />
        </button>

        <div class='recorderWrapper'>
          <AudioRecorder
            className='chat'
            {onAfterSuccess}
            {onStartRecording}
            params={{ id: selectedUser, languageId: getLearningLanguageId() }}
            reload={true}
            src={selectedUser.toString()}
            url='/chat/audioMessage'
          />
        </div>
      </form>
    </div>
  {/if}
{/if}

<style lang='scss'>
  :global(.fake-button .filterUserHeading) {
    display: none;
  }

  ._icon {
    &.-active {
      background-color: var(--Gray-Medium);
    }
  }

  .chat-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 2.4rem 2.4rem 0 0;
  }

  .user-info {
    display: flex;
    gap: 0.8rem;
    align-items: center;
  }

  .chat-input-form {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 1.6rem;
    align-items: center;
    margin: 0;
    padding: 1.6rem 2.4rem;
    background: var(--main-background);
    border: 0.1rem solid var(--Gray-Light);
    border-radius: 0 0 2.4rem 2.4rem;

    > .inputMessageField {
      flex-grow: 1;
      min-height: 3.2rem;
      padding: 0.8rem 4rem 0.8rem 1.2rem;
      color: var(--text-primary-color);
      border: 0.1rem solid var(--Gray-Light);
      border-radius: 0.8rem;

      &:invalid {
        min-height: 3.2rem;
      }

      &:valid {
        height: auto;
      }

      &::placeholder {
        color: var(--text-primary-color);
      }
    }

    > .inputMessageButton {
      padding: 0;
      color: var(--text-primary-color);
      background: transparent;
      border: 0.2rem solid transparent;
      cursor: pointer;

      &:hover {
        color: var(--Primary-Dark);
      }

      &.-active {
        color: var(--text-primary-color);
      }

      &.-sendbutton {
        position: absolute;
        right: 9.6rem;
        padding: 0;
        color: var(--Primary-Medium);
      }
    }
  }

  .all-language-flags {
    position: relative;
    gap: 0.4rem;
    align-items: baseline;
  }

  .active-language {
    &:empty {
      display: none;
    }

    > .languageSwitch {
      position: relative;
      height: 2.8rem;
      border: solid transparent 0.2rem;

      /* todo - make variants for badge and use them instead of defining inline every time */
      > :global(._badge) {
        position: absolute;
        right: -0.2rem;
        bottom: -0.2rem;
        width: 1.4rem;
        height: 1.4rem;
        padding: 0.2rem;
      }

      > hr {
        position: absolute;
        top: 2.4rem;
        width: 2.4rem;
        border: 0.2rem solid var(--Primary-Medium);
        border-radius: 0.2rem;
      }
    }
  }

  .other-languages {
    display: flex;
    gap: 0.8rem;
    align-items: center;
  }

  .reply-preview {
    position: absolute;
    top: -3.6rem;
    left: 0;
    width: 100%;
    padding: 0.8rem;
    font: var(--Regular-200);
    background-color: var(--primary-background);
    border: 0.1rem solid var(--Primary-Medium);
    border-radius: 0.4rem;

    > .closeButton {
      position: absolute;
      top: 0.4rem;
      right: 1.2rem;
      padding: 0;
      color: var(--Gray-Darker);
      background: none;
      border: none;
    }
  }

  .avatar-wrapper {
    display: flex;
    gap: 0.8rem;
    align-items: center;
    font: var(--Medium-600);

    > .avatarImage {
      object-fit: cover;
      width: 4.8rem;
      height: 4.8rem;
      border-radius: 50%;
    }
  }

  .messages-holder {
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100vh - 21rem);
    background: var(--main-background);
    border-top: none;
    border-radius: 0 0 2.4rem 2.4rem;

    > .showMore {
      margin: 1.2rem auto;
    }

    &.-chatUser {
      height: calc(100vh - 30rem);
      border-top: none;
      border-right: 0.1rem solid var(--Gray-Light);
      border-bottom: none;
      border-left: 0.1rem solid var(--Gray-Light);
      border-radius: 0;
    }
  }

  .user-input-wrapper {
    position: relative;
  }

  .new-chat-icon {
    position: relative;
    display: flex;
    gap: 0.8rem;
    align-items: center;
  }

  @media (max-width: 1200px) {
    .chat-header {
      display: flex;
      padding: 0.8rem;
      border-radius: 1.6rem 1.6rem 0 0;
      box-shadow: 0 4px 2px -2px var(--Gray-Light);
    }

    .user-input-wrapper {
      position: fixed;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 20;
      border-bottom: solid var(--Primary-Lighter) 0.2rem;
    }

    .messages-holder {
      background: var(--main-background);
    }
  }

  @media (max-width: 768px) {
    .chat-header {
      border-radius: 0;
    }

    .all-language-flags {
      margin-top: 0;
    }

    .active-language {
      display: flex;
      gap: 0.8rem;
      align-items: center;
      justify-content: center;
      padding: 0.4rem;
      background-color: var(--Gray-Light);
      border-radius: 2.4rem;
    }

    .other-languages {
      display: none;

      &.-show {
        position: absolute;
        top: 4.2rem;
        left: 0;
        z-index: 20;
        display: flex;
        flex-direction: column;
        padding: 0.8rem;
        background-color: var(--main-background);
        border-radius: 2.4rem;
        box-shadow: var(--Shadow-Z);
      }
    }

    .new-chat-languages {
      position: absolute;
      top: 4.4rem;
      right: 0;
    }

    .chat-input-form {
      gap: 0.8rem;
      border-radius: 0;

      > .inputMessageField {
        display: block;
        width: 60%;
      }

      > .recorderWrapper {
        display: inline-block;
        height: 3.6rem;
      }

      > .inputMessageButton.-sendbutton {
        display: none;
      }
    }

    .avatar-wrapper {
      font-size: 1.2rem;
    }

    .messages-holder {
      height: calc(100vh - 6rem);
      border-bottom: 0.1rem solid var(--Gray-Light);

      &.-chatUser {
        height: calc(100vh - 16rem);
      }
    }
  }
</style>
